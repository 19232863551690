import {useTheme} from '@src/core/theme/theme';
import {ComponentProps} from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import {Spinner} from '../ui/Spinner';

type ContainerProps = {
  children?: JSX.Element;
  buttonType?:
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'grey'
    | 'with-icon'
    | 'border';
};

const Container = styled.button<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44px;
  border-radius: 30px;
  opacity: ${(p) => (p.disabled ? '0.5' : '1')};
  background-color: ${(props) =>
    props.buttonType === 'primary' || props.buttonType === 'with-icon'
      ? props.theme.colors.primary500
      : props.buttonType === 'grey'
      ? props.theme.colors.primary150
      : props.buttonType === 'secondary'
      ? props.theme.colors.neutral100
      : 'transparent'};
  filter: ${(props) =>
    props.buttonType === 'grey' || props.buttonType === 'secondary'
      ? 'drop-shadow(0px 10px 20px rgba(206, 207, 242, 0.431))'
      : ''};
  lg:hover {
    background-color: ${(props) => props.theme.colors.primary200};
  }
  border: ${(props) =>
    props.buttonType === 'border' ? ' 1px solid #DDDEE8' : 'none'};
  cursor: pointer;
  color: ${(props) =>
    props.buttonType === 'secondary' ? props.theme.colors.primary500 : 'unset'};
`;

const CustomIcon = styled(Image)`
  margin-right: 5px;
`;

interface CustomButtonProps extends ComponentProps<typeof Container> {
  label: string;
  customIconName?: string;
  customIconWidth?: number;
  customIconHeight?: number;
  textColor?: string;
  loading?: boolean;
}

export const CustomButton = ({
  label,
  buttonType,
  onClick,
  disabled = false,
  children,
  customIconName,
  customIconWidth,
  customIconHeight,
  loading,
  textColor,
  ...props
}: CustomButtonProps) => {
  const {colors} = useTheme();
  return (
    <>
      <Container
        disabled={disabled}
        onClick={onClick}
        {...props}
        buttonType={buttonType}
      >
        <>
          <p
            className="text-m14"
            style={{
              color: textColor
                ? textColor
                : buttonType === 'primary' || buttonType === 'with-icon'
                ? colors.neutral100
                : colors.primary500,
              display: 'flex',
              gap: '10px',
              alignItems: 'center',
            }}
          >
            {buttonType === 'with-icon' ? (
              customIconName !== undefined ? (
                <CustomIcon
                  src={`/icons/${customIconName}.svg`}
                  width={customIconWidth}
                  height={customIconHeight}
                  alt={customIconName}
                />
              ) : children ? (
                children
              ) : (
                <svg
                  width="17"
                  height="15"
                  viewBox="0 0 17 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 7.5C0 9.35652 0.737498 11.137 2.05025 12.4497C3.36301 13.7625 5.14348 14.5 7 14.5C8.85889 14.5 10.64 13.7689 11.9778 12.4778L10.8111 11.3111C10.3213 11.8295 9.73034 12.2418 9.07483 12.5228C8.41933 12.8037 7.71317 12.9472 7 12.9444C2.14667 12.9444 -0.28 7.08 3.15 3.65C6.58 0.22 12.4444 2.65444 12.4444 7.5H10.1111L13.2222 10.6111H13.3L16.3333 7.5H14C14 5.64348 13.2625 3.86301 11.9497 2.55025C10.637 1.2375 8.85652 0.5 7 0.5C5.14348 0.5 3.36301 1.2375 2.05025 2.55025C0.737498 3.86301 0 5.64348 0 7.5Z"
                    fill="white"
                  />
                </svg>
              )
            ) : (
              <></>
            )}

            {label}
          </p>
          {loading && (
            <div style={{marginLeft: 15}}>
              <Spinner width={16} height={16}></Spinner>
            </div>
          )}
        </>
      </Container>
    </>
  );
};
